'use strict';
var processInclude = require('base/util');
const { ajax, event } = require('jquery');

processInclude(require('core/thirdParty/slick'));

'use strict';

/**
 * Get cookie value by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @returns {string} cookie value of the found cookie name
 */
function getCookie(cookieName) {
    var name = cookieName + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
        var cookieItem = cookieArray[i];
        while (cookieItem.charAt(0) === ' ') {
            cookieItem = cookieItem.substring(1);
        }
        if (cookieItem.indexOf(name) === 0) {
            return cookieItem.substring(name.length, cookieItem.length);
        }
    }
    return '';
}
/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getProductID($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
}

function subscriptionPopUp() {
    $('.subscription-button').prop('disabled', false);
    $(document).ready(function () {
        var orderMonthCount = $('.card-body').find('.focus-order-subs-dropDown option:selected').val();
        if(orderMonthCount == '') {
            $('.subscription-button').prop('disabled', true);
        }
    });
    $('body').on('change', '.focus-order-subs-dropDown', function() {
        if ($(this).val() == '') {
            $('.subscription-button').prop('disabled', true);
        } else {
            $('.subscription-button').prop('disabled', false);
        }
    });
    $('body').on('click', '.checkout-btn', function(event) {
        var orderSubscriptionEnabled = $('.subscription-enabled').attr('data-ordersubscription');
        if(orderSubscriptionEnabled == 'true') {
            event.preventDefault();
            $('.subscription-enabled').show();
            $(this).addClass('checkout-btn-disabled');
            $(this).removeClass('btn-primary');
        } else {
            $('.subscription-enabled').hide();
        } 
    });
    $('body').on('click', '.checkout-yes', function () {
        $('.subscription-enabled').hide();
        var checkoutContinueUrl = $('.checkout-continue').find('a').attr('href');
        window.location.href = checkoutContinueUrl;

    });
    $('body').on('click', '.checkout-no', function () {
        $('.subscription-enabled').hide();
        location.reload();

    });

}

module.exports = {
    groupType: function () {
        var groupTypeID = getCookie('groupTypeInfo');
        var className = $('body').find('product-set-detail')
        var pid = className.find('.product-id').text();
        var productID = getProductID(pid);

        $(document).ready(function () {
            var grptypeclass = $('.group-two-pid')
            var url = grptypeclass.find('.group-two-pid-url').attr('href');
            var productIdforGroup2Exist = grptypeclass.find('.group-two-pid-url').data('productIdforGroup2Exist');
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                data: {
                    productIdforGroup2Exist: productIdforGroup2Exist
                },
                success: function (data) {
                var grpTwoProductID = data.productIdforGroup2Exist;
                var grpTypeTwo = JSON.stringify(grpTwoProductID);
                var grouparray = productID.toString();
                var conditionSatisfies = grpTypeTwo.includes(grouparray);
                    if (groupTypeID == 2 && conditionSatisfies) {
                        $('body').find('.grouptype-addtocart-button').show();
                    } else if (groupTypeID == 1){
                        $('body').find('.grouptype-addtocart-button').show();
                    } 
                }
            });
            if (groupTypeID == 3) {
                // Mini-cart hide for group type 3
                $('body').find('.minicart-total').hide();

                //PDP page content asset handled
                $('body').find('.group-one, .group-two').hide();
            } else if (groupTypeID == 2) {
                $('body').find('.group-one, .group-three').hide();
            } else if (groupTypeID == 1) {
                $('body').find('.group-two, .group-three').hide();
            }
        });
	},
    subscriptionPopUp: subscriptionPopUp,
    getCookie: getCookie
};